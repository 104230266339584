.work_filters {
    display: flex;
    align-items: center;
    column-gap: 1.875rem;
    margin-bottom: 2.5rem;
}

.work_item {
    cursor: pointer;
    font-weight: var(--font-bold);
    color: var(--body-color);
    transition: .3s;
}

.work_item:hover {
    color: hsl(43, 100%, 68%);
}

.work_container {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
}

.work_card {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    background-color: var(--title-color);
}

.work_mask {
    background: #6c6ce5;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: .3s;
    opacity: 0;
}

.work_card:hover .work_mask {
    opacity: 0.9;
}

.work_category {
    color: #fff;
    background-color: var(--first-color);
    border-bottom-left-radius: 0.98rem;
    border-bottom-right-radius: 0.98rem;
    position: absolute;
    top: 0;
    left: 1.5rem;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 0.19rem 0.625rem;
    transform: translateY(-40px);
    transition: .3s;
}

.work_title {
    color: #fff;
    font-size: var(--h3-font-size);
    margin: 0 0 0.98rem;
    padding: 0 1.25rem;
    position: absolute;
    top: 3.75rem;
    transform: translateY(30px);
    transition: .3s;
    opacity: 0;
}

.work_button {
    color: #000;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    font-size: var(--h3-font-size);
    display: block;
    background-color: #ffd15c;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    transition: .3s;
    opacity: 0;
}

.work_card:hover .work_button {
    opacity: 1;
}

.work_card:hover .work_title,
.work_card:hover .work_category {
    opacity: 1;
    transform: translateY(0);
}