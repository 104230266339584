@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap);
/*=============== Google Fonts ===============*/

/*=============== Variables CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(353, 100%, 65%);
  --title-color: hsl(244, 24%, 26%);
  /* --text-color: hsl(244, 16%, 43%); */
  --text-color: hsl(244, 0%, 100%);
  --body-color: hsl(188, 73%, 72%);
  --container-color: #fff;

  /*========== Font and Typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: 'Rubik', sans-serif;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;

  /*========== Font Weight ==========*/
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  /*========== Box Shadow ==========*/
  --shadow: 0px 5px 20px 0px rgb(0 0 0 / 70%);

  /*========== Border Radius ==========*/
  --border-radius: 20px;
}

/*=============== Responsive Typography ===============*/
@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}


/*=============== Base ===============*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  line-height: 1.7;
  color: var(--text-color);
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
  line-height: 1.2;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*=============== Reusable CSS Classes ===============*/
.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.section {
  padding-top: 7rem;
  padding-bottom: 2rem;
}

.section_title {
  font-size: var(--h1-font-size);
  margin-left: 0.875rem;
  font-weight: var(--font-bold);
  color: var(--text-color);
  position: relative;
  margin-bottom: 3.75rem;
}

/* .section_title::before {
  content: '';
  background: url(./assets/wobbling-particles.svg);
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: -0.875rem;
  top: -0.875rem;
} */

.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  background-color: var(--first-color);
  font-weight: var(--font-bold);
}

@keyframes button-push {
  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.btn:hover {
  animation: button-push 0.3s linear 1;
}
.main {
  margin-left: 110px;
}

.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--title-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
}

.nav_list {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.nav_link {
    font-size: 1.5rem;
    color: var(--body-color);
    font-weight: var(--font-bold);
    transition: .3s;
}

.nav_link:hover {
    color: hsl(43, 100%, 68%)
}

.copyright {
    color: var(--text-color);
    font-size: var(--small-font-size);
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
}
.home {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: flex-start; /* Align items to the start of the container */
    justify-content: center;
    padding-top: 10vh; /* Add padding to control the top spacing */
}

.intro {
    max-width: 540px;
    text-align: center;
    transform: scale(1.5);
    transform-origin: center top;
}

.home_img {
    margin-bottom: 1.5rem;
}

.home_intro {
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.home_name {
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    color: var(--body-color);
    margin-bottom: 0.5rem;
}

.home_socials {
    display: flex;
    justify-content: center;
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
    margin: 1.5rem 0;
}

.home_social-link {
    color: var(--body-color);
    font-size: 1.3rem;
    transition: .3s;
}

.home_social-link:hover {
    color: hsl(43, 100%, 68%);
}

.floats {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: url(/static/media/wobbling-particles.e05463a3.svg) no-repeat center center;
    background-size: cover;
}

.about_container {
    grid-template-columns: 3fr 9fr;
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
}

.about_data {
    padding: 1.875rem;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
    align-items: flex-start;
    position: relative;
}

.about_data::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid var(--container-color);
    position: absolute;
    left: -0.93rem;
    top: 20%;
}

.about_description {
    margin-bottom: 1rem;
    text-align: justify;
    color: var(--title-color);
}

.about_skills {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
    color: var(--title-color);
}

.skills_titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.skills_name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills_number {
    line-height: 1.2;
}

.skills_bar,
.skills_percentage {
    height: 7px;
    border-radius: 0.25rem;
}

.skills_bar {
    background-color: #f1f1f1;
}

.skills_percentage {
    display: block;
}

.software {
    width: 90%;
    background-color: rgb(255, 209, 92);
}

.hardware {
    width: 80%;
    background-color: rgb(255, 76, 96);
}

.web-design {
    width: 60%;
    background-color: rgb(108, 108, 299);
}

.about_boxes {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
    margin-top: 4.35rem;
}

.about_box {
    display: flex;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
}

.about_icon {
    font-size: var(--h1-font-size);
    color: #dedeea;
}

.about_title {
    font-size: 1.875rem;
    color: var(--body-color);
}
.skills_container {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
}

.skills_card {
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: var(--container-color);
    transition: all 0.3s ease-in-out;
}

.skills_card:hover {
    transform: translateY(-20px);
}

.skills_card:nth-child(1) {
    background-color: rgb(249, 215, 76);
    box-shadow: var(--shadow);
}

.skills_card:nth-child(2) {
    background-color: rgb(249, 123, 139);
    box-shadow: var(--shadow);
}

.skills_card:nth-child(3) {
    background-color: rgb(108, 108, 229);
    box-shadow: var(--shadow);
}

.skills_img {
    margin-bottom: 1.25rem;
}

.skills_title {
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: var(--title-color);
}

.skills_description {
    color: var(--title-color);
}

.skills_card:nth-child(1) .skills_title {
    color: var(--title-color);
}

.skills_card:nth-child(1) .skills_description {
    color: var(--title-color);
}
.resume_container {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
}

.timeline {
    background-color: var(--container-color);
    padding: 1.875rem;
    border-radius: var(--border-radius);
    position: relative;
    box-shadow: var(--shadow);
}

.timeline_item {
    position: relative;
    padding-left: 3.125rem;
    padding-bottom: 3.125rem;
    color: var(--title-color);
}

.timeline_item:last-child {
    padding-bottom: 0;
}

.timeline_item::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    position: absolute;
    left: .25rem;
    top: 0;
}

.timeline .icon-briefcase,
.timeline .icon-graduation {
    position: absolute;
    left: -0.4375rem;
    top: 0;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    background-color: var(--container-color);
    padding: 0.4375rem 0;
}

.timeline_date {
    color: #8b88b1;
    font-size: var(--small-font-size);
}

.timeline_title {
    font-size: var(--h3-font-size);
    margin: 0.5rem 0;
}

.timeline_sub {
    color: #8b88b1;
}
.work_filters {
    display: flex;
    align-items: center;
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
    margin-bottom: 2.5rem;
}

.work_item {
    cursor: pointer;
    font-weight: var(--font-bold);
    color: var(--body-color);
    transition: .3s;
}

.work_item:hover {
    color: hsl(43, 100%, 68%);
}

.work_container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.875rem;
    gap: 1.875rem;
}

.work_card {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    background-color: var(--title-color);
}

.work_mask {
    background: #6c6ce5;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: .3s;
    opacity: 0;
}

.work_card:hover .work_mask {
    opacity: 0.9;
}

.work_category {
    color: #fff;
    background-color: var(--first-color);
    border-bottom-left-radius: 0.98rem;
    border-bottom-right-radius: 0.98rem;
    position: absolute;
    top: 0;
    left: 1.5rem;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 0.19rem 0.625rem;
    transform: translateY(-40px);
    transition: .3s;
}

.work_title {
    color: #fff;
    font-size: var(--h3-font-size);
    margin: 0 0 0.98rem;
    padding: 0 1.25rem;
    position: absolute;
    top: 3.75rem;
    transform: translateY(30px);
    transition: .3s;
    opacity: 0;
}

.work_button {
    color: #000;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    font-size: var(--h3-font-size);
    display: block;
    background-color: #ffd15c;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    transition: .3s;
    opacity: 0;
}

.work_card:hover .work_button {
    opacity: 1;
}

.work_card:hover .work_title,
.work_card:hover .work_category {
    opacity: 1;
    transform: translateY(0);
}
.contact.section {
    padding-bottom: 6.25rem;
}

.contact_container {
    grid-template-columns: 4fr 8fr;
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
}

.contact_title {
    font-size: var(--h3-font-size);
    margin-bottom: .5rem;
    color: var(--body-color);
}

.contact_form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
}

.contact_form-div {
    position: relative;
    margin-bottom: 1.875rem;
    height: 3.75rem;
}

.contact_form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow);
    background-color: var(--container-color);
    color: var(--title-color);
    border: none;
    outline: none;
    border-radius: var(--border-radius);
    padding: 0.625rem 1.875rem;
    z-index: 1;
}

.contact_form-area {
    height: 10.25rem;
}

.contact_form-area textarea {
    resize: none;
}
