.about_container {
    grid-template-columns: 3fr 9fr;
    column-gap: 1.875rem;
}

.about_data {
    padding: 1.875rem;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
    align-items: flex-start;
    position: relative;
}

.about_data::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid var(--container-color);
    position: absolute;
    left: -0.93rem;
    top: 20%;
}

.about_description {
    margin-bottom: 1rem;
    text-align: justify;
    color: var(--title-color);
}

.about_skills {
    row-gap: 3.5rem;
    color: var(--title-color);
}

.skills_titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.skills_name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills_number {
    line-height: 1.2;
}

.skills_bar,
.skills_percentage {
    height: 7px;
    border-radius: 0.25rem;
}

.skills_bar {
    background-color: #f1f1f1;
}

.skills_percentage {
    display: block;
}

.software {
    width: 90%;
    background-color: rgb(255, 209, 92);
}

.hardware {
    width: 80%;
    background-color: rgb(255, 76, 96);
}

.web-design {
    width: 60%;
    background-color: rgb(108, 108, 299);
}

.about_boxes {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.875rem;
    margin-top: 4.35rem;
}

.about_box {
    display: flex;
    column-gap: 1.5rem;
}

.about_icon {
    font-size: var(--h1-font-size);
    color: #dedeea;
}

.about_title {
    font-size: 1.875rem;
    color: var(--body-color);
}